import React, { FC, memo } from 'react';
import { IProductItem } from '@/Models/Product/IProduct';
import ProductItem from '../ProductItem';
import SkeletonProductItem from '../ProductItem/Skeletons/MasonrySkeleton';
import ListSkeletonProductItem from '../ProductItem/Skeletons/ListSkeleton';
import GridSkeletonProductItem from '../ProductItem/Skeletons/GridSkeleton';
import { StyledHorizontalWrapper, StyledStandartWrapper, StyledMasonryWrapper } from './newStyle';
import Masonry from '@/Components/moleculas/CustomMasonry';
import { LIST_GRID_TYPE } from '@/Types/CommonTypes';
import { NoProducts } from '@/Components/atoms/ResponseBlocks';
import { useRouter } from 'next/router';
import { useUserPreferencesState } from '@/Hooks/useUserPreferencesState';
import { AVAILABLE_PAGE_SIZE_TYPE } from '@/Data/Constants';

interface IProductListProps {
  loading: boolean;
  data: IProductItem[] | undefined;
  gridType?: LIST_GRID_TYPE;
  onRemoveFavorite?: (id: number) => void;
  noItemsTitle?: string;
  pageSize?: AVAILABLE_PAGE_SIZE_TYPE;
}

const ProductList: FC<IProductListProps> = ({
  loading,
  data,
  gridType,
  onRemoveFavorite = () => {},
  noItemsTitle,
  pageSize: pageSizeProps,
}) => {
  const listType = useUserPreferencesState('gridType');
  let pageSize = useUserPreferencesState('pageSize');
  pageSize = pageSizeProps ?? pageSize;
  const router = useRouter();

  let ListComponent = <MasonryList {...{ loading, data, onRemoveFavorite, pageSize }} />;
  switch (gridType ?? listType) {
    // case LIST_GRID_TYPE.MASONRY: {
    //   ListComponent = <MasonryList {...{ loading, data, onRemoveFavorite, pageSize }} />;
    //   break;
    // }
    case LIST_GRID_TYPE.GRID: {
      ListComponent = <StandartList {...{ loading, data, onRemoveFavorite, pageSize }} />;
      break;
    }
    case LIST_GRID_TYPE.HORIZONTAL: {
      ListComponent = <HorizontalList {...{ loading, data, onRemoveFavorite, pageSize }} />;
      break;
    }
  }

  // TODO: REFACTOR SSR NO MATCH
  if (!loading && !data?.length) {
    ListComponent = <NoProducts title={noItemsTitle} />;
  }
  // if (router.pathname === '/') {
  //   ListComponent = <StandartList {...{ loading, data, onRemoveFavorite, pageSize }} />;
  // }

  return <div>{ListComponent}</div>;
};

export default memo(ProductList);

export const StandartList: FC<IProductListProps> = ({ loading, data, onRemoveFavorite, pageSize }) => {
  const router = useRouter();
  const isHomepage = router.pathname === '/';
  const isUserPage = router.pathname.includes('/user/');

  const Skeletons = Array(pageSize)
    .fill(1)
    .map(_ => <GridSkeletonProductItem key={Math.random()} />);

  return (
    <StyledStandartWrapper data-isHomepage={isHomepage} data-isuserpage={isUserPage}>
      {!loading ? (
        !data?.length ? (
          <NoProducts />
        ) : (
          data?.map(item => (
            <ProductItem
              quality={70}
              type={LIST_GRID_TYPE.GRID}
              deferImage={true}
              item={item}
              key={item.id}
              onRemoveFavorite={onRemoveFavorite}
            />
          ))
        )
      ) : (
        Skeletons
      )}
    </StyledStandartWrapper>
  );
};

const MasonryList: FC<IProductListProps> = ({ data, loading, onRemoveFavorite, pageSize }) => {
  const router = useRouter();
  const Skeletons = Array(pageSize)
    .fill(1)
    .map(_ => <SkeletonProductItem key={Math.random()} />);

  const isHomepage = router.pathname === '/';
  const breakpointColumnsObj = {
    default: 4 + (isHomepage ? 1 : 0),
    1240: 3 + (isHomepage ? 1 : 0),
    1050: 2 + (isHomepage ? 1 : 0),
    992: 2,
  };

  return (
    <StyledMasonryWrapper>
      <Masonry breakpointCols={breakpointColumnsObj} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
        {!loading ? (
          !data?.length ? (
            <NoProducts />
          ) : (
            data?.map(item => (
              <ProductItem
                quality={70}
                type={LIST_GRID_TYPE.MASONRY}
                deferImage={true}
                item={item}
                key={item.id}
                onRemoveFavorite={onRemoveFavorite}
              />
            ))
          )
        ) : (
          Skeletons
        )}
      </Masonry>
    </StyledMasonryWrapper>
  );
};

const HorizontalList: FC<IProductListProps> = ({ data, loading, onRemoveFavorite, pageSize }) => {
  const Skeletons = Array(pageSize)
    .fill(1)
    .map(_ => <ListSkeletonProductItem key={Math.random()} />);

  return (
    <StyledHorizontalWrapper>
      {!loading ? (
        !data?.length ? (
          <NoProducts />
        ) : (
          data?.map((item, idx) => (
            <ProductItem
              quality={70}
              type={LIST_GRID_TYPE.HORIZONTAL}
              deferImage={true}
              item={item}
              key={item.id}
              onRemoveFavorite={onRemoveFavorite}
            />
          ))
        )
      ) : (
        Skeletons
      )}
    </StyledHorizontalWrapper>
  );
};
