import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { styled } from '@mui/material/styles';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import PlusSvg from '@/Components/atoms/CommonSvg/plusSvg';
import { useRouter } from 'next/router';

const StyledDiv = styled('div')`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;

  & > img {
    width: 8rem;
    height: 8rem;
  }

  & > h4 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  @media (max-width: 767.98px) {
    & > img {
      width: 4rem;
      height: 4rem;
    }

    & > h4 {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
`;

interface INoProductsProps {
  title?: string;
}

const NoProducts: FC<INoProductsProps> = ({ title }) => {
  // @ts-ignore
  const { t } = useTranslation(['common']);
  const router = useRouter();

  return (
    <StyledDiv>
      <img src='/static/images/price-tag.png' alt='' />
      <h4>{ t('search_page.no_items2')}</h4>
      <BaseButton
        onClick={() => {
          router.push('/create');
        }}
        className='add-button'
      >
        <PlusSvg /> {t('search_page.addProductBtn')}
      </BaseButton>
    </StyledDiv>
  );
};

export default NoProducts;
