import { ICategoryDocument } from '../Category';
import { IImage, ThumbnailImageItem } from '../Image';

export enum ProductType {
  NORMAL = 'NORMAL',
  URGENT = 'URGENT',
  RECOMMENDED = 'RECOMMENDED',
  PREMIUM = 'PREMIUM',
  START = 'START',
}
export enum IMAGE_MODE {
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
  LANDSCAPE = 'LANDSCAPE',
}
export enum PRODUCT_STATE {
  NEW = 'NEW',
  SECOND_HAND = 'SECOND_HAND',
}
export enum CURRENCY {
  UZS = 'UZS',
  USD = 'USD',
}
export enum SORT_KEYS {
  PRICE_CHEAP = 'price,asc',
  NEAREST = 'nearest,asc',
  NEWEST = 'newest,desc',
  PRICE_EXPENSIVE = 'price,desc',
}
export enum SORT_TYPES {
  ASC = 'asc',
  DESC = 'desc',
}

export enum PRODUCT_STATUS {
  SOLD = 'SOLD',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface IProductItem {
  id: number;
  name: string;
  description: string;
  state: PRODUCT_STATE | null;
  lat: string;
  lan: string;
  createdAt: string; // DATE
  imageMode: IMAGE_MODE;
  imageUrl: string;
  imagePath: string;
  price: string;
  createdBy: number;
  currencyType: CURRENCY;
  favoriteId: number | null;
  destination: number | null;
  type: ProductType | null;

  slug: string;
  // FAVORITE
  // destination
  distance?: number;
  favoriteCreatedAt?: string;
  shareUrl?: string;
  thumbnails: ThumbnailImageItem[];
  chatId: number | null;
  regionName?: string;
  districtName?: string;
}

export interface IUserProductItem {
  image: string;
  imagePath: string;
  name: string;
  price: string;
  currencyType: string;
  orientation: string;
  comment: string;
  status: string;
  createdAt: string;
  productView: number | null;
  favoriteCount: number | null;
  offerCount: number | null;
  chatId: number | null;
  promotionId: number | null;
  promotionExpiredDate: string;
  shareUrl: string;
  callCount: number | null;
  type: string;
  quantity: number | null;
  id: number;
}

// ----------------------------------------------------
export interface ProductPriceDto {
  id: number;
  price: number;
  sale: number;
  currencyType: string;
  currencyRate: number;
  updateAt: string;
  productId: number;
}

interface ReasonDto {
  nameUz: string;
  nameEn: string;
  nameRu: string;
  id: number;
  descriptionUz: string;
  descriptionEn: string;
  descriptionRu: string;
  description: string;
  name: string;
}
interface UserDocument {
  id: number;
  firstName: string;
  lastName: string;
  avatarId: string | null;
  avatarPath: string | null;
}

export enum PRODUCT_DETAIL_TYPE {
  BINARY_BUTTON = 'BINARY_BUTTON',
  SINGLE_LIST = 'SINGLE_LIST',
  NUMBER = 'NUMBER',
}

interface ParamValueDocument {
  nameUz: string;
  nameEn: string;
  nameRu: string;
  id: number;
  paramId: number;
  name: string;
}

export interface ProductParamDocument {
  nameUz: string;
  nameEn: string;
  nameRu: string;
  id: number;
  productId: number;
  paramId: number;
  groupId: number;
  value: string;
  type: PRODUCT_DETAIL_TYPE;
  unit: string;
  paramValues: ParamValueDocument[];
  name: string;
}

export interface IProductGroupDocument {
  nameUz: string;
  nameEn: string;
  nameRu: string;
  id: number;
  category: number;
  params: ProductParamDocument[];
  name: string;
}
export interface ITag {
  id: number | null ;
  name: string;
}

export interface IFullProductItem {
  id: number;
  category: ICategoryDocument;
  name: string;
  description: string;
  productState: string;
  status: string;
  lat: number;
  lang: number;
  images: IImage[];
  productParams: IProductGroupDocument[];
  productPrice: ProductPriceDto;
  createdAt: string;
  createdBy: number;
  phone: string;
  numberHidden: boolean;
  chatId: number;
  offerId: number;
  favorite: boolean;
  comment: string;
  reasons: ReasonDto[];
  quantity: number;
  viewCount: number;
  user: UserDocument;
  shareUrl: string;
  type: string;
  categorySlug: string;
  categorySlugRu: string;
  slug: string;
  tags: ITag[];
  freeDelivery:boolean;
  regionName?: string;
  districtName?: string;
}
