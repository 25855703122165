import { css, styled } from "@mui/material/styles";


const StyledMobileDiv = styled('div')<{noBottomPadding: boolean}>`

  
  ${props => !props.noBottomPadding ? css`
    @media (max-width: 991.98px) {
      padding-bottom: 55px;
    }

    @media (max-width: 767.98px) {
      font-size: 15px;
    }
  ` : ''}
`;

export {
  StyledMobileDiv
};
