import React, { FC } from 'react';
import { IMinCategory } from '@/Models/Category';
import PublicLayout from '../PublicLayout';
import MobileNavbar from '../Header/MobileNavbar';
import { StyledMobileDiv } from './style';
import { useIsMobile } from '@/Hooks/useIsMobile';

interface IResponsivePublicLayoutProps {
  children: React.ReactNode;
  onSearch?: (key: string, address: number) => void;
  onSelectCategory?: (cat: IMinCategory) => void;
  visibleMobileNavbar?: boolean;
  onClickLogo?: () => void;
  noBottomPadding?: boolean;
}

const ResponsivePublicLayout: FC<IResponsivePublicLayoutProps> = ({
  children,
  onSearch,
  onSelectCategory,
  onClickLogo,
  visibleMobileNavbar = false,
  noBottomPadding = false,
}) => {
  const isMobile = useIsMobile();

  const MobilePublicLayout = (
    <StyledMobileDiv noBottomPadding={noBottomPadding}>
      {children}

      {visibleMobileNavbar && <MobileNavbar />}
    </StyledMobileDiv>
  );

  const DesktopPublicLayout = (
    <PublicLayout onClickLogo={onClickLogo} onSearch={onSearch} onSelectCategory={onSelectCategory}>
      {children}
    </PublicLayout>
  );

  if (isMobile) {
    return MobilePublicLayout;
  }

  return DesktopPublicLayout;
};

export default ResponsivePublicLayout;
