import React, { FC } from "react";
import { useIsMobile } from "@/Hooks/useIsMobile";
import { IMAGE_MODE } from "@/Models/Product/IProduct";
import Skeleton from 'react-loading-skeleton';

interface ISkeletonProductItem {};

function getRandomMode(): IMAGE_MODE {
  const num = Math.random();
  if(num > 0.67) {
    return IMAGE_MODE.PORTRAIT;
  }
  if(num > 0.33) {
    return IMAGE_MODE.SQUARE;
  }
  return IMAGE_MODE.LANDSCAPE;
}

const GridSkeletonProductItem: FC<ISkeletonProductItem> = (props) => {
  const isMobile = useIsMobile();
  const imageMode = getRandomMode();
  let imgHeight = 100;
  let className = '';

  switch (imageMode) {
    case IMAGE_MODE.LANDSCAPE: {
      className = 'card_small';
      imgHeight = isMobile ? 90 : 1.444 * 90;
      break;
    }
    case IMAGE_MODE.SQUARE: {
      className = 'card_medium';
      imgHeight = isMobile ? 1.444 * 90 : 2.666 * 90;
      break;
    }
    case IMAGE_MODE.PORTRAIT: {
      className = 'card_large';
      imgHeight = isMobile ? 1.777 * 90 : 3.111 * 90;
      break;
    }
  }

  return (
    <div className={`product-item-skeleton ${className}`}>
      <Skeleton height={160} style={{ marginBottom: 8 }}  />
      <Skeleton count={1} height={12} style={{marginBottom: 2, width: '65%' }} />
      <Skeleton count={1} height={12} style={{marginBottom: 2, width: '45%' }} />
      <Skeleton count={1} height={12} style={{marginBottom: 2, width: '100%' }} />
    </div>
  )
}

export default GridSkeletonProductItem;
