import React, { FC } from 'react';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { IMAGE_MODE } from '@/Models/Product/IProduct';
import Skeleton from 'react-loading-skeleton';
import { ListSkeleton } from '../style';

interface IListSkeletonProductItem {}

const ListSkeletonProductItem: FC<IListSkeletonProductItem> = props => {
  return (
    <ListSkeleton className={`product-item-skeleton`}>
      <div className='wrap'>
        <div className='left'>
          <Skeleton height={150} style={{ marginBottom: 8, width: '100%' }} />
        </div>
        <div className='right'>
          <Skeleton count={1} height={12} style={{ marginBottom: 2, width: '65%' }} />
          <Skeleton count={3} height={12} style={{ marginBottom: 2, width: '80%' }} />
        </div>
      </div>
      <div className='wrap'>
        <div className='left'>
          <Skeleton height={150} style={{ marginBottom: 8, width: '100%' }} />
        </div>
        <div className='right'>
          <Skeleton count={1} height={12} style={{ marginBottom: 2, width: '65%' }} />
          <Skeleton count={3} height={12} style={{ marginBottom: 2, width: '80%' }} />
        </div>
      </div>
    </ListSkeleton>
  );
};

export default ListSkeletonProductItem;
