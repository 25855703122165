import { styled, css } from '@mui/material/styles';

const StyledHorizontalWrapper = styled('div')`
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .product-item__img {
    aspect-ratio: 1 / 1 !important;
  }

  .product-item {
    /* margin-bottom: 40px; */
  }
`;

const StyledMasonryWrapper = styled('div')`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > * {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }

  .product-item__title {
    display: block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 991.98px) {
    .my-masonry-grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-left: -15px;
      width: auto;
      padding-top: 20px;
    }
    .my-masonry-grid_column {
      padding-left: 15px;
      background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > * {
      margin-bottom: 15px;
    }
  }
`;

const StyledStandartWrapper = styled('div')<{ 'data-isHomepage': boolean; 'data-isuserpage'?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  & > * {
    width: calc((100% - (30px * 4)) / 5);
    /* width: ${props => (props['data-isHomepage'] ? 'calc((100% - (30px * 4)) / 5)' : 'calc((100% - (30px * 3)) / 4)')}; */
    height: auto;

    display: flex;
    flex-direction: column;

    .product-item__img,
    .product-item__hover-screen-img {
      aspect-ratio: 1 / 1 !important;
      flex-grow: 1;
    }

    ${props => {
      const isUserPage = props['data-isuserpage'];
      const isHomePage = props['data-isHomepage'];
      return isUserPage
        ? css`
            width: calc((100% - (30px * 2)) / 3);
            @media (max-width: 991.98px) {
              width: ${'calc((100% - (15px * 1)) / 2)'};
            }
          `
        : css`
            @media (max-width: 1240px) {
              width: ${isHomePage ? 'calc((100% - (30px * 3)) / 4)' : 'calc((100% - (30px * 2)) / 3)'};
            }
            @media (max-width: 1050px) {
              width: ${isHomePage ? 'calc((100% - (30px * 2)) / 3)' : 'calc((100% - (30px * 1)) / 2)'};
            }
            @media (max-width: 991.98px) {
              width: ${'calc((100% - (15px * 1)) / 2)'};
            }
          `;
    }}
  }

  @media (max-width: 991.98px) {
    padding-top: 20px;
    gap: 15px;
  }
`;

const ButtonsStyle = styled('div')`
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select {
    select {
      padding: 5px;
      padding-right: 15px;
      color: #0c88c6;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background: #fff;
      option {
        color: #0c88c6;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
      }
    }
  }
  .btns {
    text-align: end;
    display: flex;
    justify-content: end;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 10px;
      background-color: #fff;
      border: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      svg {
        width: 20px;
        height: 20px;
        color: #fff;
        path {
          color: ${props => props.theme.customTheme.bg.dark300};
        }
      }
    }

    button.active {
      background-color: ${props => props.theme.customTheme.bg.primary};
      svg {
        color: #fff !important;
        path {
          color: #fff;
        }
      }
    }
  }
`;

export { StyledStandartWrapper, ButtonsStyle, StyledMasonryWrapper, StyledHorizontalWrapper };
